import { RefreshIcon } from '@heroicons/react/outline'
import { useState } from 'react'

import { updateCurrentSlide } from 'data/decks'
import { selectCurrentSlide } from 'data/decks/selectors'
import { useAppSelector } from 'data/reduxHooks'
import { useCaptureSlide } from 'hooks/useCaptureSlide'
import BackgroundImageModal from './BackgroundImageModal'

export const SlideOverlaySelector = () => {
  const { captureSlide } = useCaptureSlide()
  const { currentSlide } = useAppSelector(selectCurrentSlide)
  const [showOverlayGallery, setShowOverlayGallery] = useState(false)

  function setAsOverlay(img: { url: string }) {
    updateCurrentSlide({
      overlayImage: img.url,
    })
    captureSlide()
  }

  return (
    <>
      <div className="flex items-center justify-between">
        <button
          onClick={() => setShowOverlayGallery(true)}
          className="flex items-center hover:text-gray-400"
        >
          <RefreshIcon className="h-5 w-5" />
          <span className="text-3xs">Choose photo</span>
        </button>
        {currentSlide.overlayImage && (
          <img className="object-cover h-[30px] w-[30px]" src={currentSlide.overlayImage} />
        )}
      </div>
      <BackgroundImageModal
        onClose={() => setShowOverlayGallery(false)}
        onSelect={(img) => {
          setAsOverlay(img)
          setShowOverlayGallery(false)
        }}
        show={showOverlayGallery}
      />
    </>
  )
}
