import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { memo } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import { generateInitials } from 'utils/helpers'

import { ExportButton } from 'components/buttons/ExportButton'
import { ExportCsvButton } from 'components/buttons/ExportCsvButton'
import { ExportQuoteButton } from 'components/buttons/ExportQuoteButton'
import { SaveDeckButton } from 'components/buttons/SaveDeckButton'
import { Button } from 'components/small/Button'

import { clearDeck, startNewDeck } from 'data/decks'
import { resetPriceAdjustOptions, setCurrentList, setFilters, setSearchTerm } from 'data/globals'
import { logOut } from 'data/user'
import { selectIsAdmin, selectUser } from 'data/user/selectors'

import { useImageStore } from 'hooks/useImageStore'
import { ReactComponent as GPSLogo } from '../assets/svgs/gps_logo.svg'

interface INavBarProps {
  builder: boolean
}
export const NavBar = memo(({ builder }: INavBarProps) => {
  const { clearAllImages } = useImageStore()
  const history = useHistory()
  const location = useLocation()

  const isAdmin = useSelector(selectIsAdmin)

  function goHome() {
    clearAllImages()
    history.push('/')
  }

  function startNew() {
    clearAllImages()
    resetPriceAdjustOptions()
    startNewDeck()
    history.push('/builder')
  }

  function handleExit() {
    clearDeck()
    setFilters([])
    setSearchTerm('')
    setCurrentList(null)
    clearAllImages()
    setTimeout(() => {
      history.replace('/products')
    }, 500)
  }

  return (
    <nav className="fixed left-0 right-0 top-0 z-50 bg-gray-800 min-h-[67px]">
      <div className="max-w-8xl mx-auto px-8 sm:px-16">
        <div className="flex h-16 items-center justify-between">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <GPSLogo onClick={goHome} className="h-6 w-auto cursor-pointer text-white md:h-8" />
            </div>
          </div>
          <div className="block">
            <div className="ml-4 flex items-center md:ml-6">
              {isAdmin && !location?.pathname?.includes('builder') && (
                <Button onClick={startNew} variant="primary" className="mr-4" shadow="none">
                  + NEW DECK
                </Button>
              )}
              {builder && (
                <div className="flex gap-4">
                  <ExportQuoteButton />
                  <ExportCsvButton />
                  <ExportButton />
                  <SaveDeckButton />
                  <Button
                    variant="primary"
                    size="s"
                    onClick={handleExit}
                    className="mr-4"
                    shadow="none"
                  >
                    EXIT BUILDER
                  </Button>
                </div>
              )}

              {/* Profile dropdown */}
              {!builder && <NavbarMenu />}
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
})

NavBar.displayName = 'NavBar'

const NavbarMenu = memo(() => {
  const history = useHistory()
  const user = useSelector(selectUser)
  const isAdmin = useSelector(selectIsAdmin)

  const handleProfile = () => {
    history.push(`/profile/${user?.id}`)
  }

  const handleViewProducts = () => {
    history.push('/home')
  }

  const handleManageUsers = () => {
    history.push('/admin/user_list')
  }

  const handleSignOut = async () => {
    await logOut()
    localStorage.clear()
    history.replace('/login')
  }

  const menuItemButtonClasses =
    'block w-full text-left data-[focus]:bg-gray-100 text-sm text-gray-800 px-2 py-1'

  return (
    <Menu as="div" className="relative z-20 ml-3">
      <MenuButton className="flex max-w-xs items-center rounded-full bg-gray-800 text-sm text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
        <span className="sr-only">Open user menu</span>
        <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-500 text-sm text-white">
          {generateInitials(user)}
        </div>
      </MenuButton>
      <MenuItems
        anchor="bottom end"
        className="[--anchor-gap:8px] z-50 w-32 rounded-md bg-white py-1 shadow-full-dark ring-0 focus:outline-none"
      >
        <MenuItem>
          <button onClick={handleProfile} className={menuItemButtonClasses}>
            My Profile
          </button>
        </MenuItem>
        <MenuItem>
          <button onClick={handleViewProducts} className={menuItemButtonClasses}>
            Search Products
          </button>
        </MenuItem>
        {isAdmin && (
          <MenuItem>
            <button onClick={handleManageUsers} className={menuItemButtonClasses}>
              Manage Users
            </button>
          </MenuItem>
        )}
        <MenuItem>
          <button onClick={handleSignOut} className={menuItemButtonClasses}>
            Sign Out
          </button>
        </MenuItem>
      </MenuItems>
    </Menu>
  )
})

NavbarMenu.displayName = 'NavbarMenu'
