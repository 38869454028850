import { PlusCircleIcon } from '@heroicons/react/outline'
import { useRef, useState } from 'react'
import { useAlert } from 'react-alert'
import { useSelector } from 'react-redux'

import { TextInput } from 'components/inputs/TextInput'
import { GenericModal } from 'components/modals/GenericModal'
import { Button } from 'components/small/Button'
import { directoryLevels } from 'components/tree/Directory'
import { setCurrentDirectory } from 'data/globals'
import { api } from 'services/api'
import { generateInitials } from 'utils/helpers'

// TODO only show add buttons if slot is null
const ProfileHeader = ({ user, activeTabIndex, onNewListAddComplete }) => {
  const alert = useAlert()

  const currentDir = useSelector((state) => state.globals.currentDirectory)

  const [showNewListModal, setShowNewListModal] = useState(false)
  const [showNewFolderModal, setShowNewFolderModal] = useState(false)
  const [listName, setListName] = useState('')
  const [folderName, setFolderName] = useState('')

  const newListRef = useRef(null)
  const newFolderRef = useRef(null)

  function handleShowAddNewListModal() {
    setShowNewListModal(true)
    setTimeout(() => {
      newListRef?.current?.focus()
    }, 500)
  }

  function handleCloseNewList() {
    setShowNewListModal(false)
    setListName('')
  }

  async function handleAddNewList() {
    if (!listName.trim()) return
    try {
      await api.List.create({ userId: user.id + '', name: listName })
      setShowNewListModal(false)
      setListName('')
      onNewListAddComplete()
      alert.success('List created')
    } catch (error) {
      alert.error(error.message)
    }
  }

  function handleShowAddNewFolderModal() {
    setShowNewFolderModal(true)
    setTimeout(() => {
      newListRef?.current?.focus()
    }, 500)
  }

  async function handleAddFolder() {
    if (!folderName.trim()) return
    const parentDir = { ...currentDir }
    try {
      await api.Directory.create({
        userId: parentDir.userId,
        name: folderName,
        parentId: parentDir.id,
        type: 'folder',
        level: directoryLevels[parentDir.level].child,
      })
      const userDirWithChildren = await api.Directory.getWithChildren(currentDir.id)
      setCurrentDirectory(userDirWithChildren)
      setShowNewFolderModal(false)
      setFolderName('')
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      <img
        className="h-32 w-full object-cover lg:h-48"
        src={`${process.env.REACT_APP_BUCKET_BASE_URL}/public/portal-general/banner_vegas.jpeg`}
        alt=""
      />
      <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8 xl:max-w-7xl">
        <div className="-mt-12 flex">
          <div className="hidden h-24 w-24 items-center justify-center rounded-full bg-gray-500 text-5xl text-white sm:flex">
            {generateInitials(user)}
          </div>
          <div className="mt-6 sm:flex sm:min-w-0 sm:flex-1 sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
            <div className="ml-2 mt-6 min-w-0 flex-1">
              <h1 className="truncate text-2xl font-bold text-gray-900">{user?.name}</h1>
            </div>
            <div className="mt-auto flex flex-col justify-stretch space-y-3 sm:flex-row sm:space-x-4 sm:space-y-0">
              {activeTabIndex === 0 && (
                <Button onClick={handleShowAddNewListModal} variant="outline">
                  <PlusCircleIcon className="-ml-1 h-5 w-5 text-green-500" aria-hidden="true" />
                  <span>New List</span>
                </Button>
              )}
              {activeTabIndex === 1 && (
                <Button onClick={handleShowAddNewFolderModal} variant="outline">
                  <PlusCircleIcon className="-ml-1 h-5 w-5 text-green-500" aria-hidden="true" />
                  <span>New Folder</span>
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
      <GenericModal
        show={showNewListModal}
        title="New List Name"
        onClose={handleCloseNewList}
        onAccept={handleAddNewList}
      >
        <TextInput ref={newListRef} onChange={setListName} value={listName} />
      </GenericModal>
      <GenericModal
        show={showNewFolderModal}
        title="New Folder"
        onClose={() => setShowNewFolderModal(false)}
        onAccept={handleAddFolder}
      >
        <TextInput ref={newFolderRef} onChange={setFolderName} value={folderName} />
      </GenericModal>
    </>
  )
}

export default ProfileHeader
