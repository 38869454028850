/* eslint-disable react/display-name */
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react'

import { classNames } from '../../utils/helpers'
import BackgroundImageModalPassportTab from './BackgroundImagePassportTab'
import BackgroundImageModalUnsplashTab from './BackgroundImageUnsplashTab'

interface IBackgroundImageTabsProps {
  onSelect: (img: { url: string }) => void
}
export const BackgroundImageTabs = ({ onSelect }: IBackgroundImageTabsProps) => {
  const tabs = {
    Passport: {
      render: () => <BackgroundImageModalPassportTab onSelect={onSelect} />,
    },
    Unsplash: {
      render: () => <BackgroundImageModalUnsplashTab onSelect={onSelect} />,
    },
  }

  return (
    <div className="flex-grow h-full w-full pb-2">
      <TabGroup className="h-full">
        <TabList className="flex space-x-1 rounded-xl p-1 px-2">
          {Object.keys(tabs).map((category) => (
            <Tab
              key={category}
              className={({ selected }) =>
                classNames(
                  selected
                    ? 'border-purple-500 text-gray-900'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  'whitespace-nowrap border-b-2 py-2 pl-1 pr-8 text-left text-sm font-medium outline-none ring-0 focus:outline-none',
                )
              }
            >
              {category}
            </Tab>
          ))}
        </TabList>
        <TabPanels className="h-full">
          {Object.values(tabs).map((tab, idx) => (
            <TabPanel key={idx} className="h-full focus:outline-none focus:ring-0">
              {tab.render()}
            </TabPanel>
          ))}
        </TabPanels>
      </TabGroup>
    </div>
  )
}
