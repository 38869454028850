import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

import { USER_LOGOUT } from './types'

import { signOut } from 'services/auth'
import { IUser } from 'types/modelTypes'

type UserState = IUser | null
const initialState = null as UserState

export const logOut = createAsyncThunk(USER_LOGOUT, async (_, thunkAPI) => {
  try {
    await signOut()
    window.localStorage.clear()
    return
  } catch (error) {
    if (axios.isAxiosError(error)) {
      thunkAPI.rejectWithValue(error.message)
    }
    return thunkAPI.rejectWithValue('An unknown error occurred')
  }
})

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<IUser>) => {
      return action.payload
    },
    updateUser: (state, action: PayloadAction<IUser>) => {
      return { ...state, ...action.payload }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(logOut.fulfilled, () => {
        return initialState
      })
      .addCase(logOut.rejected, (state, action) => {
        console.log('logOut.rejected', action)
        return state
      })
  },
})

// Action creators are generated for each case reducer function
export const { setUser, updateUser } = userSlice.actions

export default userSlice.reducer
