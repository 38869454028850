import { TrashIcon } from '@heroicons/react/solid'
import axios from 'axios'
import { useMemo, useState } from 'react'
import { useAlert } from 'react-alert'
import { useHistory } from 'react-router-dom'

import { formatOrgTierPricing, resizeComputedImage } from 'utils/helpers'

import { addProductToSlide, createSlideFromProduct } from 'data/decks'
import { addFavorites, removeFavorites } from 'data/favorites'

import { ProductSelectionType } from 'components/ProductsView'
import { Checkbox } from 'components/inputs/Checkbox'
import { WarningModal } from 'components/modals/WarningModal'
import { Button } from 'components/small/Button'
import { CheckboxHeart } from 'components/small/CheckboxHeart'

import { ISortOption } from 'data/globals/types'
import { api } from 'services/api'
import { IContinuity, IProduct } from 'types/modelTypes'

interface IProductCardProps {
  id: string
  product: IProduct
  favorites?: any
  isAdmin?: boolean
  showFavorites?: boolean
  onSelect: (data: ProductSelectionType) => void
  isSelected?: boolean
  onBrandClick?: (brand: string) => void
  onVendorClick?: (vendor: string) => void
  onAddComplete: () => void
  slot?: any
  page?: any
  currentSlide?: IContinuity
  orgMarkup: number
  currentList?: any
  onDelete?: () => void
  sortParams: ISortOption
  fromNotes?: boolean
  fromList?: boolean
}
export const ProductCard = ({
  id,
  product,
  favorites,
  isAdmin,
  showFavorites = true,
  onSelect,
  isSelected = false,
  onBrandClick,
  onVendorClick,
  onAddComplete = () => {},
  slot = null,
  page = null,
  currentSlide,
  orgMarkup,
  currentList = null,
  onDelete,
  sortParams,
  fromNotes = false,
  fromList = false,
}: IProductCardProps) => {
  const alert = useAlert()
  const history = useHistory()

  const [hoverActive, setHoverActive] = useState(false)
  const [showDeleteWarningModal, setShowDeleteWarningModal] = useState(false)

  async function handleFavorite() {
    try {
      await addFavorites([product.id])
    } catch (error) {
      if (axios.isAxiosError(error)) {
        alert.error(error.message || 'Error setting favorite')
      }
    }
  }

  async function handleUnfavorite() {
    try {
      await removeFavorites([product.id])
    } catch (error) {
      if (axios.isAxiosError(error)) {
        alert.error(error.message || 'Error setting favorite')
      }
    }
  }

  function viewDetails() {
    // if we have a slot, we are working from within the builder
    history.push({
      pathname: `/products/${product.id}`,
      state: {
        favorite: favorites?.includes(product.id),
        slot: slot,
        from: slot !== null ? '/builder' : fromNotes ? '/builder' : '/products',
        currentList: slot !== null ? true : currentList,
        fromNotes: fromNotes,
        fromList: fromList,
        page,
        sortState: sortParams,
      },
    })
  }

  async function deleteProductFromList() {
    try {
      await api.List.removeProduct(currentList.id, product.id)
      onDelete?.()
      setShowDeleteWarningModal(false)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        alert.error(error.message || 'Error deleting product')
      }
    }
  }

  function renderDeleteButton() {
    if (hoverActive && currentList) {
      return (
        <button
          className="absolute right-2 top-2 cursor-pointer z-[1]"
          onClick={() => setShowDeleteWarningModal(true)}
        >
          <TrashIcon className="h-5 w-5 text-red-500 hover:text-red-700" />
        </button>
      )
    }
    return null
  }

  function renderFavoriteButton() {
    if (!showFavorites || slot !== null) return
    return hoverActive || favorites?.includes(product.id) ? (
      <CheckboxHeart
        isChecked={favorites?.includes(product.id)}
        handleUnfavorite={() => handleUnfavorite()}
        handleFavorite={() => handleFavorite()}
      />
    ) : (
      <div className="w-5" />
    )
  }

  function renderSelectedButton() {
    if (!showFavorites || slot !== null) return
    if (isSelected || hoverActive) {
      return (
        <Checkbox
          id="product_checkbox"
          onChange={(value) => handleCheckbox({ id: Number(product.id), add: value, product })}
          checked={isSelected}
          containerStyle="mr-1"
        />
      )
    }
  }

  function renderAddToSlideButton() {
    if (slot === null && !fromNotes) return
    return hoverActive ? (
      <Button onClick={handleAddToSlide} variant="primary" size="xs">
        Add To Slide
      </Button>
    ) : (
      <div className="w-5" />
    )
  }

  const handleAddToSlide = () => {
    if (currentSlide?.products.some((p) => p.id === product.id)) {
      alert.error('Product already in slide')
      return
    }
    if (fromNotes) {
      createSlideFromProduct(product)
    } else {
      addProductToSlide({ product, slot })
    }
    onAddComplete()
  }

  const handleCheckbox = (data: ProductSelectionType) => {
    onSelect(data)
  }

  function onMouseEnter() {
    setHoverActive(true)
  }

  function onMouseLeave() {
    setHoverActive(false)
  }

  function handleModalClose() {
    setShowDeleteWarningModal(false)
  }

  const imageSource = useMemo(() => {
    return product.transparentImage
      ? product.transparentImage
      : resizeComputedImage(product.computedPrimaryImage, 600, 600)
  }, [product])

  return (
    <>
      <section
        key={product.id}
        id={id}
        className="relative h-full shadow-full-light hover:shadow-full-dark"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {renderDeleteButton()}
        <li className="relative flex h-full flex-col justify-between">
          <button
            onClick={viewDetails}
            className="focus-within:none group aspect-h-10 aspect-w-10 flex w-full cursor-pointer overflow-hidden rounded-sm bg-white outline-none focus:outline-none"
          >
            <img
              src={imageSource || 'https://placehold.jp/24/ffffff/444444/250x250.png?text=Pending'}
              alt={product.name}
              className="object-contain group-hover:opacity-75"
            />
          </button>
          <div className="mb-2 min-h-10 px-5 py-5">
            <button
              onClick={() => (product.brand ? onBrandClick?.(product.brand) : null)}
              className="block cursor-pointer text-sm font-medium text-gray-500 hover:opacity-75"
            >
              {product.brand || 'Unknown'}
            </button>
            {product.vendor && (
              <button
                onClick={() => onVendorClick?.(product.vendor!)}
                className="block cursor-pointer text-left text-sm font-thin text-gray-500 hover:opacity-75"
              >
                {product.vendor || 'Unknown'}
              </button>
            )}
            <button
              onClick={viewDetails}
              className="line-clamp-3 text-left w-3/4 pr-1 text-base font-semibold text-gray-900"
            >
              {product.name || 'Unknown'}
            </button>
          </div>
          <div className="flex w-full flex-grow items-end justify-between px-5 pb-5">
            <p className="block text-sm font-semibold text-gray-800">
              {formatOrgTierPricing(product.cost, orgMarkup, isAdmin)}
            </p>
            <div className="flex h-5 items-center">
              {renderSelectedButton()}
              {renderFavoriteButton()}
              {renderAddToSlideButton()}
            </div>
          </div>
        </li>
      </section>
      <WarningModal
        title={'Are you sure you want to remove this product?'}
        show={showDeleteWarningModal}
        onAccept={deleteProductFromList}
        onClose={handleModalClose}
        onCancel={handleModalClose}
      />
    </>
  )
}
