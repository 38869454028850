import { v4 as uuidV4 } from 'uuid'
import { AndOrType, ComparisonOperator, IAdminFilter } from '../../data/globals/types'
// import { CHECKBOX_STATES } from '../inputs/TriStateCheckbox'

export function createFilterFromAttribute(
  prop: string,
  attr: string | number,
  operator: string,
  type?: AndOrType,
  label?: string,
): IAdminFilter {
  const newFilter = {
    key: uuidV4(),
    complete: true,
    prop: {
      label: `${prop[0].toUpperCase()}${prop.slice(1)}`,
      value: prop,
      type: type || 'string',
    },
    attr: [
      {
        name: String(attr),
        label: String(attr),
        value: String(attr),
      },
    ],
    operator: {
      op: '=' as ComparisonOperator,
      label: label || 'Equals',
      value: operator,
      type: type ? [type] : ['string', 'bool', 'int'],
    },
    andOr: AndOrType.and,
  }
  return newFilter
}

// export function handleAndFilterChange(
//   checked: boolean,
//   value: string | number,
//   type: AndOrType,
//   operator: string,
//   filters: string[],
//   setFilters: (filters: string[]) => void,
// ) {
//   console.log('CHANGE', value, type, operator)
//   // an 'AND' or 'multiple value' filter will look like this {'Color':{'red','blue'}}
//   // find the filter by type in the filters array if exists
//   const currentFilter = filters.find((f) => f.includes(type))
//   if (checked) {
//     // if box is being checked and filter exists, replace existing filter with new filter
//     if (currentFilter) {
//       // get filter values from filterText eg 'red','blue'
//       const f = currentFilter.split(':')[1].replace(/[{}]/g, '')
//       setFilters([
//         ...filters.filter((filter) => !filter.includes(type)),
//         `${type}:{${f}|${value}}:${operator}`,
//       ])
//     } else {
//       // else just add the new filter
//       setFilters([...filters, `${type}:{${value}}:${operator}`])
//     }
//   } else {
//     // if box is unchecked
//     // get exising filters values and convert to array eg ['red'|'blue']
//     const f = currentFilter?.split(':')[1].replace(/[{}]/g, '')
//     const allValues = f?.split('|') || []
//     // remove the unchecked value from the array
//     const newF = allValues.filter((fil) => fil !== value)
//     if (newF.length === 0) {
//       // if the array of values is empty, remove the filter completely
//       setFilters([...filters.filter((filter) => !filter.includes(type))])
//     } else {
//       // else replace the exisiting filter with the new values
//       setFilters([
//         ...filters.filter((filter) => !filter.includes(type)),
//         `${type}:{${newF.join('|')}}:or`,
//       ])
//     }
//   }
// }

// export function handleAndFilterChange(checked, value, type, operator, filters, setFilters) {
//   // console.log('CHANGE', value, type, operator)
//   // an 'AND' or 'multiple value' filter will look like this {'Color':{'red','blue'}}
//   // find the filter by type in the filters array if exists
//   const currentFilter = filters.find((f) => f.includes(type))
//   if (checked === CHECKBOX_STATES.Checked) {
//     // if box is being checked and filter exists, replace existing filter with new filter
//     if (currentFilter) {
//       // get filter values from filterText eg 'red','blue'
//       const f = currentFilter.split(':')[1].replace(/[{}]/g, '')
//       setFilters([
//         ...filters.filter((filter) => !filter.includes(type)),
//         `${type}:{${f}|${value}}:${operator}`,
//       ])
//     } else {
//       // else just add the new filter
//       setFilters([...filters, `${type}:{${value}}:${operator}`])
//     }
//   } else if (checked === CHECKBOX_STATES.Indeterminate) {
//     console.log('REMOVE CHECKED VAL AND REPLACE WITH INDETERMINATE VAL')
//     // get exising filters values and convert to array eg ['red'|'blue']
//     const f = currentFilter.split(':')[1].replace(/[{}]/g, '')
//     const allValues = f.split('|')
//     // remove the unchecked value from the array
//     const newF = allValues.filter((fil) => fil === value)
//     if (newF.length === 0) {
//       // if the array of values is empty, remove the filter completely
//       setFilters([...filters.filter((filter) => !filter.includes(type))])
//     } else {
//       // else replace the exisiting filter with the new values
//       setFilters([
//         ...filters.filter((filter) => !filter.includes(type)),
//         `${type}:{${newF.join('|')}}:${operator}`,
//       ])
//     }
//   } else if (checked === CHECKBOX_STATES.Empty) {
//     // if box is unchecked
//     // get exising filters values and convert to array eg ['red'|'blue']
//     const f = currentFilter.split(':')[1].replace(/[{}]/g, '')
//     const allValues = f.split('|')
//     // remove the unchecked value from the array
//     const newF = allValues.filter((fil) => fil !== value)
//     if (newF.length === 0) {
//       // if the array of values is empty, remove the filter completely
//       setFilters([...filters.filter((filter) => !filter.includes(type))])
//     } else {
//       // else replace the exisiting filter with the new values
//       setFilters([
//         ...filters.filter((filter) => !filter.includes(type)),
//         `${type}:{${newF.join('|')}}:or`,
//       ])
//     }
//   }
// }

// export function handleOrFilterChange(checked, value, type, operator, filters, setFilters) {
//   if (checked) {
//     setFilters([...filters.filter((filter) => !filter.includes(type)), `${value}:${operator}`])
//   } else {
//     setFilters(filters.filter((f) => f !== value))
//   }
// }
