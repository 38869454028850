import { Button } from 'components/small/Button'
import useCSVExport from 'hooks/useCSVExport'
import { memo } from 'react'

interface IExportCsvButtonProps {
  className?: string
}
export const ExportCsvButton = memo(({ className }: IExportCsvButtonProps) => {
  const { exportCSV } = useCSVExport()

  function onExport() {
    exportCSV()
  }

  return (
    <Button variant="primary" size="s" onClick={onExport} className={className} shadow="none">
      EXPORT TO CSV
    </Button>
  )
})

ExportCsvButton.displayName = 'ExportCsvButton'
