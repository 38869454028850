import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  PointerSensor,
  UniqueIdentifier,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { MenuIcon } from '@heroicons/react/solid'
import { SyntheticEvent, memo, useEffect, useState } from 'react'

import { classNames } from 'utils/helpers'

import { setCurrentSlideIndex, updateCurrentDeck } from 'data/decks'
import { useAppSelector } from 'data/reduxHooks'
import { useCaptureSlide } from 'hooks/useCaptureSlide'
import { useImageStore } from 'hooks/useImageStore'
import { IContinuity } from 'types/modelTypes'
import EmptyImage from '../../assets/images/empty_image.png'

const Image = memo(function Image({ thumbnailId }: { thumbnailId: string }) {
  const [src, setSrc] = useState<string | undefined>(undefined)
  const { getImage } = useImageStore()

  useEffect(() => {
    const fetchImage = async () => {
      const blob = await getImage(thumbnailId)
      if (blob) {
        // console.log('SEttinG BLOB', blob)
        const objectUrl = URL.createObjectURL(blob)
        setSrc(objectUrl)

        // Clean up the object URL when the component unmounts
        return () => URL.revokeObjectURL(objectUrl)
      } else {
        setSrc(thumbnailId)
      }
    }

    fetchImage()
  }, [thumbnailId])

  const handleError = (e: SyntheticEvent<HTMLImageElement, Event>) => {
    e.currentTarget.src = 'https://placehold.jp/210x120.png?text=Click to reload'
    // e.currentTarget.src = value.products?.[0]?.computedPrimaryImage
    //   ? value.products?.[0]?.computedPrimaryImage
    //   : 'https://placehold.jp/210x120.png?text=Click to reload'
  }

  return (
    <img
      onError={handleError}
      className={classNames(`w-[130px] bg-white object-contain`)}
      src={src}
    />
  )
})

interface ISortableItemProps {
  id: string
  value: IContinuity
  idx: number
  currentSlideIndex: number
}
const SortableItem = memo(({ id, value, idx, currentSlideIndex }: ISortableItemProps) => {
  const { captureSlide } = useCaptureSlide()
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id })

  const [hovered, setHovered] = useState(false)

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    height: '90px',
    width: '200px',
  }

  const handleThumbnailClick = async (idx: number) => {
    setCurrentSlideIndex(idx)
    if (idx === currentSlideIndex) {
      captureSlide()
    }
  }

  return (
    <div
      ref={setNodeRef}
      style={style}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      className={classNames(
        'flex items-center rounded-sm shadow-full',
        idx === currentSlideIndex ? 'bg-purple-100' : 'bg-white hover:bg-gray-100',
      )}
    >
      <div className="flex w-full items-center justify-evenly">
        <span
          className={classNames(
            `text-sm`,
            idx === currentSlideIndex ? 'text-purple-500' : 'text-gray-800',
          )}
        >
          {idx + 1}
        </span>
        <button onClick={() => handleThumbnailClick(idx)}>
          {value.thumbnail ? (
            <Image thumbnailId={value.thumbnail} />
          ) : !value.products?.[0]?.computedPrimaryImage ? (
            <img
              className={classNames(
                `aspect-2 w-[130px] bg-white object-contain`,
                idx === currentSlideIndex && 'border border-purple-500',
              )}
              src={value.products?.[0]?.computedPrimaryImage}
              // src={value.thumbnail || 'https://placehold.jp/210x120.png?text=Click to reveal'}
            />
          ) : (
            <img
              className={classNames(
                `aspect-2 w-[130px] bg-white object-cover`,
                idx === currentSlideIndex && 'border border-purple-500',
              )}
              src={EmptyImage}
            />
          )}
        </button>
        {hovered ? (
          <MenuIcon className="h-4 w-4" {...attributes} {...listeners} />
        ) : (
          <span className="h-4 w-4" />
        )}
      </div>
    </div>
  )
})

SortableItem.displayName = 'SortableItem'

export default memo(function Thumbnails() {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )

  const deck = useAppSelector((state) => state.decks)
  const currentSlideIndex = useAppSelector((state) => state.decks.slideIndex)

  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null)

  function handleDragStart(event: DragStartEvent) {
    const { active } = event

    setActiveId(active.id)
  }

  async function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event

    if (active.id !== over?.id) {
      const items = [...deck.continuities]
      const oldIndex = items.findIndex((i) => i.id === active.id)
      const newIndex = items.findIndex((i) => i.id === over?.id)
      const newOrderedSlides = arrayMove(items, oldIndex, newIndex)
      updateCurrentDeck({
        continuities: newOrderedSlides,
      })
    }

    setActiveId(null)
  }

  if (deck.continuities.length < 1) {
    return null
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      <SortableContext
        items={deck.continuities.map((c) => String(c.id))}
        strategy={verticalListSortingStrategy}
      >
        {deck.continuities.map((item, idx) => (
          <SortableItem
            key={`${item.id}-${Date.now()}`}
            idx={idx}
            currentSlideIndex={currentSlideIndex}
            id={String(item.id)}
            value={item}
          />
        ))}
      </SortableContext>
      <DragOverlay>
        {activeId ? (
          <div
            style={{ height: '90px', width: '200px' }}
            className={classNames('flex items-center rounded-sm bg-gray-100 opacity-50')}
          >
            <div className="flex w-full items-center justify-evenly">
              <span className="text-sm">
                {deck.continuities.findIndex((c) => c.id === activeId) + 1}
              </span>
              <img
                className="-ml-4 bg-white object-contain"
                style={{ width: '130px' }}
                src={
                  deck.continuities.find((c) => c.id === activeId)?.thumbnail ||
                  'http://placehold.jp/210x120.png'
                }
              />
            </div>
          </div>
        ) : null}
      </DragOverlay>
    </DndContext>
  )
})
