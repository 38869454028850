import { useEffect, useState } from 'react'
import { useAlert } from 'react-alert'
import { useQuery } from 'react-query'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'

import AdvancedFilters from 'components/filters/AdvancedFilters'
import { selectFilterString } from 'data/globals/selectors'
import { updateSelectedProducts } from 'data/selections'
import { selectUser } from 'data/user/selectors'
import { api } from 'services/api'

import { LoadingSpinner } from './LoadingSpinner'
import { MenuGeneric } from './MenuGeneric'
import { MultiSelectBar } from './MultiSelectBar'
import { MyListCard } from './MyListCard'
import { SearchInput } from './inputs/SearchInput'

import { useAppSelector } from 'data/reduxHooks'
import { SelectedProducts } from 'data/selections/types'
import { useErrorAlert } from 'hooks/useErrorAlert'
import { IList } from 'types/modelTypes'
import { ReactComponent as EmptyState } from '../assets/svgs/empty_state.svg'
import { FilterButton } from './small/FilterButton'

const SORT_OPTIONS = [
  { id: 1, name: 'Recently added', value: 'updatedAt', desc: true },
  { id: 2, name: 'Name - A-Z', value: 'name', desc: false },
  { id: 3, name: 'Name - Z-A', value: 'name', desc: true },
]

interface IMyListsTabListsProps {
  forceFetch: boolean
}
export function MyListsTabLists({ forceFetch }: IMyListsTabListsProps) {
  const alert = useAlert()
  const { errorAlert } = useErrorAlert()
  const history = useHistory()
  const { url } = useRouteMatch()
  const { id } = useParams<{ id: string }>() // user id

  const user = useAppSelector(selectUser)
  const currentList = useAppSelector((state) => state.globals.currentList)
  const filterString = useAppSelector(selectFilterString)

  const [searchTerm, setSearchTerm] = useState('')
  const [disabledId, setDisabledId] = useState<number | null>(null)
  const [sort, setSort] = useState(SORT_OPTIONS[0])
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false)

  function handleListClick(list: IList) {
    history.push(`${url}/list/${list.id}`)
  }

  function handleSelectAll() {
    const newSelected = {} as SelectedProducts
    currentList?.products?.forEach((p) => {
      newSelected[p.id] = p
    })
    updateSelectedProducts(newSelected)
  }

  const fetchMyLists = async () => {
    if (!user?.id && id) {
      return []
    }

    const userId = id || user?.id
    const { data } = await api.List.getForUser({
      // lists can be viewed by people other than the current user
      userId: Number(userId),
      search: searchTerm,
      order: sort.desc ? 'DESC' : 'ASC',
      order_by: sort.value,
      filter: filterString.length > 0 ? filterString.join('_') : '',
    })
    return data
  }

  const { isLoading, error, data, refetch } = useQuery(
    ['lists', searchTerm, user, id, sort, ...filterString, forceFetch],
    () => fetchMyLists(),
    {
      keepPreviousData: disabledId ? false : true, // keeping previous when deleting is causing old lists to reappear if deleting more than one
      refetchOnWindowFocus: true,
    },
  )

  useEffect(() => {
    if (error) {
      errorAlert(error ?? 'Error loading lists')
    }
  }, [error, alert])

  async function handleDelete(list: IList) {
    setDisabledId(list.id)
    try {
      await api.List.delete(list.id)
      refetch()
      alert.success('List deleted')
    } catch (error) {
      errorAlert(error ?? 'Error deleting list')
    } finally {
      setDisabledId(null)
    }
  }

  const handleAdvancedFilterClick = () => {
    setShowAdvancedFilters((prev) => !prev)
  }

  const handleSearch = (term: string) => {
    setSearchTerm(term)
  }

  return (
    <>
      <div className="flex w-full flex-col">
        <div className="sticky top-0 z-10 flex min-h-[54px] items-center justify-between bg-white py-6">
          <SearchInput className="h-9 w-1/2" onChange={handleSearch} />
          <FilterButton onClick={handleAdvancedFilterClick} />
          <div className="ml-auto">
            <MenuGeneric<string> options={SORT_OPTIONS} value={sort} onChange={setSort} />
          </div>
        </div>
        {showAdvancedFilters && (
          <div className="mb-6">
            <AdvancedFilters onClose={() => setShowAdvancedFilters(false)} />
          </div>
        )}
        {data?.length === 0 && !isLoading ? (
          <div className="mt-6 w-full">
            <p className="text-center font-semibold">
              Oops... Sorry we did not find anything that matches this search.
            </p>
            <EmptyState className="mx-auto h-64" />
          </div>
        ) : (
          <ul
            role="list"
            className="scrollbar grid grid-cols-1 gap-x-4 gap-y-8 overflow-y-scroll px-4 pb-24 pt-2 sm:grid-cols-3 sm:gap-x-6 xl:grid-cols-4 xl:gap-x-8"
          >
            {data?.map((list) => (
              <MyListCard
                key={list.id}
                item={list}
                onClick={handleListClick}
                onDelete={handleDelete}
                disabled={list.id === disabledId}
              />
            ))}
          </ul>
        )}
      </div>
      {user && <MultiSelectBar onSelectAll={handleSelectAll} user={user} />}
      {isLoading && (
        <div className="fixed bottom-8 right-8">
          <LoadingSpinner />
        </div>
      )}
    </>
  )
}
