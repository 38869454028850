import { useState } from 'react'
import { useAlert } from 'react-alert'
import { Link, useHistory } from 'react-router-dom'

import { setUser } from '../../data/user'
import { signIn } from '../../services/auth'
import CONSTANTS from '../../utils/constants'

import { NewUserResetPassword } from './NewUserResetPassword'

import { Button } from 'components/small/Button'
import { ReactComponent as GPSLogo } from '../../assets/svgs/gps_logo.svg'
import { api } from '../../services/api'
import { ConfirmForgotPassword } from './ConfimForgotPassword'

export const Login = () => {
  const history = useHistory()
  const alert = useAlert()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [cognitoUser, setCognitoUser] = useState(null)
  const [disabled, setDisabled] = useState(false)
  const [newPasswordRequired, setNewPasswordRequired] = useState(false)
  const [confirmForgotPassword, setConfirmForgotPassword] = useState(false)

  async function login(e) {
    e?.preventDefault()
    setDisabled(true)
    try {
      const user = await signIn(email, password)
      // console.log('USER', user)
      setCognitoUser(user) // needs to be instance of CognitoUser class to perform newPasswordReset, which we lose when we store it in redux
      switch (user.challengeName) {
        case 'NEW_PASSWORD_REQUIRED':
          // user createrd in aws console need redirect to change password
          alert.success('Please create a new password')
          setNewPasswordRequired(true)
          break
        case 'SMS_MFA':
          break
        case 'SOFTWARE_TOKEN_MFA':
          break
        case 'MFA_SETUP':
          break
        default:
          let existingUser = null
          const { data } = await api.User.getByUUID(user.username)
          existingUser = data
          if (!existingUser) {
            throw new Error('User not found')
            // const createdResp = await api.User.create({
            //   uuid: user.username,
            //   name: user.attributes['name'],
            //   email: user.attributes['email'],
            //   role: user.attributes['custom:role'] === 'Admins' ? 'admin' : 'user',
            //   status: 'active',
            //   organization: '',
            // })
            // existingUser = createdResp.data
          } else if (existingUser.status === 'invited') {
            const updatedResp = await api.User.update(existingUser.id, {
              status: 'active',
            })
            existingUser = updatedResp.data
          }
          setUser({
            ...existingUser,
            accessToken: user.signInUserSession.accessToken.jwtToken,
          })

          localStorage.setItem(CONSTANTS.STORED_TOKEN, user.signInUserSession.accessToken.jwtToken)
          alert.success('Login Successful.')
          history.replace('/home')
          break
      }
    } catch (error) {
      if (error.code === 'PasswordResetRequiredException') {
        // users who have had their password reset by admin will get caught here
        alert.success('Please create a new password')
        setConfirmForgotPassword(true)
        setDisabled(false)
        return
      }
      console.error(error)
      setDisabled(false)
      alert.error(error.message)
    }
  }

  async function onError() {
    setNewPasswordRequired(false)
  }

  return newPasswordRequired ? (
    <NewUserResetPassword cognitoUser={cognitoUser} onError={onError} />
  ) : confirmForgotPassword ? (
    <ConfirmForgotPassword email={email} onError={onError} />
  ) : (
    <div className="flex min-h-screen items-center justify-center bg-gray-50 px-4 py-12 sm:px-6 lg:px-8">
      <div className="w-full max-w-md space-y-8">
        <div>
          <GPSLogo className="mx-auto h-12 w-auto text-purple-500" />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-purple-500">
            WELCOME TO PASSPORT
          </h2>
        </div>
        <form className="mt-8 space-y-6" onSubmit={login}>
          <input type="hidden" name="remember" defaultValue="true" />
          <div className="-space-y-px rounded-md shadow-sm">
            <div>
              <label htmlFor="email-address" className="sr-only">
                Company Email
              </label>
              <input
                id="email-address"
                name="email"
                type="email"
                data-cy="email"
                autoComplete="email"
                required
                onChange={(e) => setEmail(e.target.value)}
                className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-purple-500 focus:outline-none focus:ring-purple-500 sm:text-sm"
                placeholder="Company Email"
              />
            </div>
            <div>
              <label htmlFor="password" className="sr-only">
                Password
              </label>
              <input
                id="password"
                name="password"
                type="password"
                data-cy="password"
                autoComplete="current-password"
                required
                onChange={(e) => setPassword(e.target.value)}
                className="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                placeholder="Password"
              />
            </div>
          </div>

          <div className="flex items-center justify-between">
            <div className="flex items-center">
              {/* <input
                id="remember_me"
                name="remember_me"
                type="checkbox"
                className="h-4 w-4 text-purple-600 focus:ring-purple-500 border-gray-300 rounded"
              />
              <label htmlFor="remember_me" className="ml-2 block text-sm text-gray-900">
                Remember me
              </label> */}
            </div>

            <div className="text-sm">
              <Link
                to="/forgot_password"
                className="font-medium text-purple-500 hover:text-purple-300"
              >
                Forgot your password?
              </Link>
            </div>
          </div>

          <Button variant="primary" fullWidth type="submit" disabled={disabled} data-cy="login">
            Sign in
          </Button>

          {/* <div className="flex items-center justify-center mx-12">
            <hr className="w-1/3" />
            <p className="w-8 text-center text-sm text-gray-600">Or</p>
            <hr className="w-1/3" />
          </div>

          <Link
            type="button"
            className="group relative w-full flex justify-center py-2 px-4 border border-purple-500 text-sm font-medium rounded-md text-purple-500 hover:text-purple-300 bg-white hover:border-purple-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
            to="/signup"
          >
            Create an account
          </Link> */}
        </form>
      </div>
    </div>
  )
}
