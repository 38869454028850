import { useState } from 'react'

import ProductsView from 'components/ProductsView'
import { GenericModal } from 'components/modals/GenericModal'
import { Button } from 'components/small/Button'

export default function ProductAccessModal({ title, orgName, show, onClose, slot, addProducts }) {
  const [products, setProducts] = useState([])

  function handleAddComplete() {
    addProducts(products)
  }

  return (
    <GenericModal title={title} show={show} onClose={onClose} showButtons={false} fullWidth={true}>
      <div className="flex justify-between">
        <div>
          <h3 className="text-xl font-bold">
            Customize Available Products {orgName ? `for ${orgName}` : ''}
          </h3>
          <span className="text-sm">
            Select which products will be visible to members of this organization.
            <br />
            Only products included in the custom filters will be shown.
          </span>
        </div>
        <div className="mr-8">
          <Button onClick={onClose} variant="outline" className="mr-2" size="s">
            Cancel
          </Button>
          <Button onClick={handleAddComplete} variant="primary" size="s">
            Save
          </Button>
        </div>
      </div>
      <div className="relative flex-grow">
        <ProductsView
          slot={slot}
          onAddComplete={handleAddComplete}
          useCache={true}
          isModal={true}
          builder={true}
          onProductsChange={setProducts}
        />
      </div>
    </GenericModal>
  )
}
