import { memo } from 'react'
import { ReactComponent as Heart } from '../../assets/svgs/heart.svg'
import { ReactComponent as HeartFilled } from '../../assets/svgs/heart_filled.svg'

interface ICheckboxHeartProps {
  isChecked: boolean
  handleFavorite: () => void
  handleUnfavorite: () => void
}
export const CheckboxHeart = memo(
  ({ isChecked, handleFavorite, handleUnfavorite }: ICheckboxHeartProps) => {
    return isChecked ? (
      <HeartFilled onClick={handleUnfavorite} className="z-10 h-5 w-5 cursor-pointer" />
    ) : (
      <Heart onClick={handleFavorite} className="z-10 h-5 w-5 cursor-pointer" />
    )
  },
)

CheckboxHeart.displayName = 'CheckboxHeart'
