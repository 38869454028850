import * as htmlToImage from 'html-to-image'

import { updateCurrentSlide } from 'data/decks'
import { useImageStore } from './useImageStore'

export const useCaptureSlide = () => {
  const { saveImage } = useImageStore()

  const hashBlob = async (blob: Blob): Promise<string> => {
    const arrayBuffer = await blob.arrayBuffer()
    const hashBuffer = await crypto.subtle.digest('SHA-256', arrayBuffer)
    const hashArray = Array.from(new Uint8Array(hashBuffer))
    const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('')
    return hashHex
  }

  const captureSlide = async (): Promise<Blob> => {
    // todo pass in the slide index and do not rely on currentSlide
    return new Promise(async (resolve, reject) => {
      setTimeout(async () => {
        const node = document.querySelector('#capture') as HTMLElement
        if (!node) return reject(new Error('Node not found'))

        try {
          const blob = await htmlToImage.toBlob(node, {
            quality: 0.6,
            cacheBust: true,
            filter: function (node) {
              // do not capture elements with the 'ignore-capture' class and their children
              return !node.classList?.contains?.('ignore-capture')
            },
          })

          if (!blob) {
            return reject(new Error('Failed to create blob'))
          }

          // const dataUrl = URL.createObjectURL(blob)
          const hash = await hashBlob(blob)
          updateCurrentSlide({
            thumbnail: hash,
          })
          saveImage(hash, blob)

          resolve(blob)
        } catch (error) {
          console.error('htmlToImage error', error)
          reject(error)
        }
      }, 600)
    })
  }

  return {
    captureSlide,
  }
}
