import { DBSchema, openDB } from 'idb'

const DB_NAME = 'ImageStore'
const DB_VERSION = 1
const STORE_NAME = 'images'

interface ImageStoreDB extends DBSchema {
  images: {
    key: string
    value: {
      id: string
      blob: Blob
    }
  }
}

const _openDB = async () => {
  return openDB<ImageStoreDB>(DB_NAME, DB_VERSION, {
    upgrade(db) {
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        db.createObjectStore(STORE_NAME, { keyPath: 'id' })
      }
    },
  })
}

export const saveImage = async (id: string, blob: Blob) => {
  const db = await _openDB()
  await db.put(STORE_NAME, { id, blob })
}

export const getImage = async (id: string) => {
  const db = await _openDB()
  const result = await db.get(STORE_NAME, id)
  return result?.blob
}

export const deleteImage = async (id: string) => {
  const db = await _openDB()
  await db.delete(STORE_NAME, id)
}

export const clearAllImages = async () => {
  const db = await _openDB()
  await db.clear(STORE_NAME)
}

export const useImageStore = () => {
  return {
    saveImage,
    getImage,
    deleteImage,
    clearAllImages,
  }
}
