import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'data/store'

export const selectFilterString = createSelector(
  (state: RootState) => state.globals.filters,
  (filters) => {
    return filters
      .map((f) => {
        if (!f || !f.prop || !f.operator) {
          // if filter has no property value or operator value, it's invalid
          return false
        }
        if (f.attr?.length === 0 && f.operator?.value !== 'none' && f.operator?.value !== 'any') {
          // all operators besides 'any' and 'none' require attributes
          return false
        }

        return `${f.prop?.value}:{${f.attr?.map((a) => a.value).join('|')}}:${f.operator?.value}:${
          f.andOr
        }`
      })
      .filter((f): f is string => typeof f === 'string')
  },
)

export const PRODUCT_SORT_OPTIONS = {
  admin: [
    { id: 1, name: 'Recently added', value: 'salsifyUpdatedAt', desc: true },
    { id: 2, name: 'Cost - Low-High', value: 'cost', desc: false },
    { id: 3, name: 'Cost - High-Low', value: 'cost', desc: true },
    { id: 4, name: 'Inventory - High-Low', value: 'inventory', desc: true },
    { id: 5, name: 'Inventory - Low-High', value: 'inventory', desc: false },
  ],
  user: [
    { id: 1, name: 'Recently added', value: 'salsifyUpdatedAt', desc: true },
    { id: 2, name: 'Price - Low-High', value: 'cost', desc: false },
    { id: 3, name: 'Price - High-Low', value: 'cost', desc: true },
    { id: 4, name: 'Inventory - High-Low', value: 'inventory', desc: true },
    { id: 5, name: 'Inventory - Low-High', value: 'inventory', desc: false },
  ],
}

export const CONTINUITY_SORT_OPTIONS = {
  admin: [
    { id: 1, name: 'Recently added', value: 'updatedAt', desc: true },
    { id: 2, name: 'Price - Low-High', value: 'price', desc: false },
    { id: 3, name: 'Price - High-Low', value: 'price', desc: true },
  ],
  user: [
    { id: 1, name: 'Recently added', value: 'updatedAt', desc: true },
    { id: 2, name: 'Price - Low-High', value: 'price', desc: false },
    { id: 3, name: 'Price - High-Low', value: 'price', desc: true },
  ],
}

export const selectProductSortOptions = createSelector(
  (state: RootState) => state.user?.role,
  (userRole) => {
    return PRODUCT_SORT_OPTIONS[userRole ?? 'user']
  },
)

export const selectContinuitySortOptions = createSelector(
  (state: RootState) => state.user?.role,
  (userRole) => {
    return CONTINUITY_SORT_OPTIONS[userRole ?? 'user']
  },
)
