import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { useEffect, useState } from 'react'

import { classNames } from '../utils/helpers'
import { GenericSelectMenuOption } from './continuityBuilder/Controls'

interface IMenuGenericProps<T> {
  onChange: (value: any) => void
  value: any
  options: GenericSelectMenuOption<T>[]
  currentOption?: GenericSelectMenuOption<T>
}
export const MenuGeneric = <T,>({
  onChange,
  value,
  options,
  currentOption,
}: IMenuGenericProps<T>) => {
  const [selected, setSelected] = useState(
    currentOption || { id: 0, name: 'Selected Options', value: null },
  )

  useEffect(() => {
    if (value) {
      setSelected(value)
    }
  }, [value])

  function handleChange(value: GenericSelectMenuOption<T>) {
    setSelected(value)
    onChange(value)
  }

  return (
    <Listbox value={selected} onChange={handleChange}>
      <ListboxButton
        as="button"
        className={`relative flex text-center text-xs text-gray-800 focus:outline-none focus:ring-0`}
      >
        <span className="flex items-center gap-1">
          <SelectorIcon className="h-4 w-4 text-gray-800" aria-hidden="true" />
          <span className="truncate">{selected.name}</span>
        </span>
      </ListboxButton>
      <ListboxOptions
        anchor="bottom end"
        className="[--anchor-gap:8px] z-20 min-w-36 rounded-md bg-white py-1 shadow-full-dark"
      >
        {options.map((o) => (
          <ListboxOption
            key={o.id}
            value={o}
            className="cursor-default select-none py-1 px-3 text-xs text-gray-800 data-[focus]:bg-gray-100"
          >
            {({ selected }) => (
              <div className="flex items-center justify-between">
                <span
                  className={classNames(
                    selected ? 'font-semibold' : 'font-normal',
                    'block truncate',
                  )}
                >
                  {o.name}
                </span>
                <span className={classNames('pl-1.5 text-gray-800', !selected && 'invisible')}>
                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                </span>
              </div>
            )}
          </ListboxOption>
        ))}
      </ListboxOptions>
    </Listbox>
  )
}
