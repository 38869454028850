export const REMOVE_PRODUCT_FROM_LIST = 'globals/removeProductFromList'
export const REMOVE_PRODUCTS_FROM_LIST = 'globals/removeProductsFromList'

export enum AndOrType {
  'and' = 'and',
  'or' = 'or',
  'int' = 'int',
}
export interface IAdminFilter {
  key: string
  complete: boolean
  prop: {
    label: string
    value: string
    type: string
  } | null
  attr:
    | {
        name: string
        label: string
        value: string
      }[]
    | null
  operator: {
    op: ComparisonOperator
    label: string
    value: string
    type: string[]
  } | null
  andOr: AndOrType | null
}

export interface IDirectoryCrumb {
  name: string
  level: string
  directoryId: number | null
  userId: number | null
  current: boolean
}

export interface ISortOption {
  id: number
  name: string
  value: string
  desc: boolean
}

// Logical operators to combine filters
export type LogicalOperator = 'and' | 'or'

// Comparison operators for individual fields.
// You can add more operators as needed.
export type ComparisonOperator =
  | '='
  | '!='
  | '>'
  | '<'
  | '>='
  | '<='
  | 'contains'
  | 'not_contains'
  | 'startsWith'
  | 'endsWith'
  | 'any' // For matching any one of multiple values (similar to "in")
  | 'none' // For checking that none of the values match (not in)
  | 'is_null' // Field is null
  | 'is_not_null' // Field is not null

export interface BaseFilter {
  field: string // The field to filter on (e.g., "brand")
  operator: ComparisonOperator // The operator (e.g., "=", "contains")
  value: string | number | boolean | (string | number | boolean)[] // The value to compare against
}

// A group filter that can combine multiple filters with a logical operator
export interface GroupFilter {
  operator: LogicalOperator // "and" or "or"
  filters: Filter[] // Array of nested filters (which can be simple or groups)
}

// A filter can be either a BaseFilter or a GroupFilter
export type Filter = BaseFilter | GroupFilter

// Optionally, if you have a root filter object that always uses a group format:
export interface FilterQuery {
  operator: LogicalOperator
  filters: Filter[]
}
