import { XIcon } from '@heroicons/react/solid'

import { MenuGeneric } from 'components/MenuGeneric'
import { setFilters } from 'data/globals'

export default function ResultsAndSortSection({
  data,
  userRole,
  filters,
  onSetCurrentSort,
  onSetPage,
  hideSort = false,
  sortOptions,
  sort,
}) {
  function handleRemoveFilter(filterKey) {
    const newFilters = filters.filter((f) => f.key !== filterKey)
    setFilters(newFilters)
  }

  return (
    <div className="sticky top-0 z-10 flex min-h-[32px] w-full items-start justify-between border-b border-gray-300 bg-white pb-1 pt-2">
      <div className="flex flex-grow items-center flex-wrap">
        <p className="inline-flex pr-6 text-xs">
          Results:{' '}
          {data?.meta?.total_results < 100 ? data?.meta?.total_results : (data?.meta?.limit ?? 0)}{' '}
          of {Math.ceil(data?.meta?.total_results ?? 0)}
        </p>
        {filters
          .filter((f) => f.complete)
          .map((f, idx) => {
            return (
              <button
                onClick={() => handleRemoveFilter(f.key)}
                key={idx}
                className="transition-colors mb-1 mr-2 flex items-center rounded bg-gray-100 px-2 py-1 text-xs text-gray-600 duration-200 hover:bg-gray-200"
              >
                {f.operator?.value === 'gte' ? '> ' : f.operator?.value === 'lte' ? '< ' : ''}
                {f.attr?.[0]?.label} <XIcon className="ml-1 h-3 w-3" />
              </button>
            )
          })}
      </div>
      {!hideSort && (
        <MenuGeneric
          options={sortOptions}
          value={sort}
          onChange={(val) => {
            onSetCurrentSort(val)
            onSetPage(0)
          }}
        />
      )}
    </div>
  )
}
