import { Dialog, DialogPanel, DialogTitle } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { Button } from 'components/small/Button'

import { classNames } from 'utils/helpers'

interface IGenericModalProps {
  title: string
  children: React.ReactNode
  show: boolean
  showButtons?: boolean
  fullWidth?: boolean
  onClose: () => void
  onAccept?: () => void
  onCancel?: () => void
  acceptText?: string
  cancelText?: string
  loading?: boolean
}
export const GenericModal = ({
  title,
  children,
  show,
  showButtons = true,
  fullWidth = false,
  onClose = () => {},
  onAccept = () => {},
  onCancel,
  acceptText = 'OK',
  cancelText = 'Cancel',
  loading = false,
}: IGenericModalProps) => {
  function handleClose() {
    onClose()
  }

  function handleAccept() {
    onAccept()
  }

  function handleCancel() {
    if (onCancel) {
      onCancel()
    } else {
      onClose()
    }
  }

  return (
    <Dialog
      as="div"
      transition
      open={show}
      className="fixed inset-0 z-40 flex w-screen items-center justify-center bg-gray-500/75 p-4 transition duration-300 ease-out data-[closed]:opacity-0"
      onClose={handleClose}
    >
      <div
        className={classNames(
          'transition-all inline-block transform overflow-hidden rounded-lg bg-white pb-4 pt-5 text-left align-bottom shadow-xl sm:my-8 sm:align-middle',
          fullWidth
            ? 'h-80vh max-h-80vh w-full max-w-7xl'
            : 'min-w-1/2 max-w-lg sm:max-w-md md:max-w-xl max-h-screen',
        )}
      >
        <DialogPanel className="flex flex-col h-full">
          <div className="absolute right-0 top-0 z-20 hidden pr-4 pt-4 sm:block">
            <button
              type="button"
              className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0"
              onClick={handleClose}
            >
              <span className="sr-only">Close</span>
              <XIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>

          <DialogTitle as="h3" className="text-lg px-5 font-medium leading-6 text-gray-900">
            {title}
          </DialogTitle>

          {/* Flexible children container */}
          <div className="flex-1 overflow-y-scroll px-5">{children}</div>

          {showButtons && (
            <div className="mt-5 px-5 flex items-center flex-col sm:flex-row justify-end gap-3">
              <Button
                variant="warning"
                size="s"
                disabled={loading}
                loading={loading}
                onClick={handleAccept}
              >
                {acceptText}
              </Button>
              <Button disabled={loading} size="s" onClick={handleCancel} variant="outline">
                {cancelText}
              </Button>
            </div>
          )}
        </DialogPanel>
      </div>
    </Dialog>
  )
}
