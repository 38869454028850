// @ts-nocheck
import {
  Document,
  DocumentProps,
  Form,
  FormField,
  Image,
  Page,
  StyleSheet,
  Text,
  TextInput,
  View,
  usePDF,
} from '@kjossendal/renderer'
import { memo, useEffect, useRef, useState } from 'react'

import GPSLogo from 'assets/images/gps_logo.png'

import { Button } from 'components/small/Button'
import { IDeckSliceInitialState } from 'data/decks/types'
import { useAppSelector } from 'data/reduxHooks'
import { api } from 'services/api'
import { IContinuity, IProduct } from 'types/modelTypes'
import { formatter, resizeComputedImage, roundUp } from 'utils/helpers'

const PRODUCTS_PER_PAGE = 6
const PRODUCTS_PER_LAST_PAGE = 4

type ProductWithQuantity = IProduct & { quantity: string }
type SlidesByType = {
  singleProducts: ProductWithQuantity[]
  continuities: (Omit<IContinuity, 'products'> & { products: ProductWithQuantity[] })[]
}
type SlidesByTypePaginated = {
  singleProducts: ProductWithQuantity[][]
  continuities: IContinuity[]
}
export type OrgMarkupTiers = {
  tier1: number
  tier2: number
  tier3: number
  tier4: number
  tier5: number
}

interface IExportPDFButtonProps {
  className?: string
}
export const ExportQuoteButton = memo(({ className }: IExportPDFButtonProps) => {
  const [pdfInstance, updatePdfInstance] = usePDF({}) // Instantiate an empty PDF instance using the usePDF hook

  const downloadRef = useRef<HTMLAnchorElement | null>(null)
  const doItOnceRef = useRef(false)

  const deck = useAppSelector((state) => state.decks)
  const userEmail = useAppSelector((state) => state.user?.email)

  const [quoteNumber, setQuoteNumber] = useState('')
  const [startDownload, setStartDownload] = useState(false)

  useEffect(() => {
    api.Quote.getQuoteNumber()
      .then(({ data }) => {
        setQuoteNumber(data.number)
      })
      .catch((error) => {
        console.log('error', error)
      })
  }, [])

  /* 
	all single item slides are to be displayed on one quote
	all multi item slides (continuities) are to be displayed on separate quotes
	*/
  const slidesByType = deck.continuities.reduce<SlidesByType>(
    (prev, curr) => {
      if (curr.type === 'title') {
        return prev
      }
      if (curr.type === 'single') {
        const products = curr.products
          ?.filter((p) => !!p.uuid)
          // override cost with formatted modified cost
          ?.map((p) => ({
            ...p,
            cost: roundUp(p.cost / curr.priceModifier),
            quantity: curr.quantity ?? '1', // quantity is stored at the slide level and applies to all products in that slide
          }))
        return {
          ...prev,
          singleProducts: [...prev.singleProducts, ...products],
        }
      } else {
        const withCostModifiedProducts = {
          ...curr,
          products: curr.products?.map((p) => ({
            ...p,
            cost: roundUp(p.cost / curr.priceModifier),
            quantity: curr.quantity ?? '1',
          })),
        }
        return {
          ...prev,
          continuities: [...prev.continuities, withCostModifiedProducts],
        }
      }
    },
    {
      singleProducts: [],
      continuities: [],
    },
  )
  const pages: ProductWithQuantity[][] = []
  for (let i = 0; i < slidesByType.singleProducts.length; i += PRODUCTS_PER_PAGE) {
    let page = slidesByType.singleProducts.slice(i, i + PRODUCTS_PER_PAGE)

    pages.push(page)
    if (
      i + PRODUCTS_PER_PAGE > slidesByType.singleProducts.length &&
      page.length > PRODUCTS_PER_LAST_PAGE
    ) {
      // make sure to push the footer to another page if it wont fit on the last page
      pages.push([])
    }
  }
  const slidesByTypeWithPaginatedProducts: SlidesByTypePaginated = {
    ...slidesByType,
    singleProducts: pages,
  }

  // async function downloadPdf() {
  //   try {
  //     await api.Quote.incrementQuoteNumber(2)
  //     if (downloadRef.current) {
  //       downloadRef.current?.click()
  //     }
  //   } catch (error) {
  //     console.log('error', error)
  //   }
  // }

  useEffect(() => {
    if (!startDownload) {
      return
    }
    setStartDownload(false)

    const doc = (
      <DocLayout
        continuities={slidesByType.continuities}
        singleProducts={slidesByTypeWithPaginatedProducts.singleProducts}
        deck={deck}
        quoteNumber={quoteNumber} // TODO pull this from local state
        userEmail={userEmail || ''}
      />
    )
    doItOnceRef.current = true
    updatePdfInstance(doc) // Update the PDF instance with your document when the download button is clicked
  }, [deck, quoteNumber, slidesByType, slidesByTypeWithPaginatedProducts, userEmail, startDownload])

  useEffect(() => {
    if (pdfInstance.loading) {
      return
    }
    if (pdfInstance.error) {
      console.log('PDF ERROR', pdfInstance.error)
      return
    }
    if (!pdfInstance.blob || !(pdfInstance.blob instanceof Blob)) {
      return
    }
    const blob = pdfInstance.blob

    if (!blob || !(blob instanceof Blob)) return

    if (!doItOnceRef.current) {
      return
    }

    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = `quote.pdf`
    downloadRef.current = link
    link.click()

    doItOnceRef.current = false

    api.Quote.incrementQuoteNumber(2).catch(console.log)

    return () => {
      window.URL.revokeObjectURL(link.href)
    }
  }, [pdfInstance.loading, pdfInstance.error, pdfInstance.blob])

  const onExportClick = () => {
    setStartDownload(true)
  }

  return (
    <Button onClick={onExportClick} size="s" className={className} variant="primary" shadow="none">
      EXPORT QUOTE
    </Button>
  )
})

interface IDocLayoutProps {
  singleProducts: ProductWithQuantity[][]
  continuities: (Omit<IContinuity, 'products'> & { products: ProductWithQuantity[] })[]
  deck: IDeckSliceInitialState
  quoteNumber: string
  userEmail: string
}
const DocLayout = memo(
  ({
    singleProducts,
    continuities,
    deck,
    quoteNumber,
    userEmail,
  }: IDocLayoutProps): React.ReactElement<DocumentProps> => {
    return (
      <Document>
        {singleProducts.map((products, idx) => (
          <DocumentPage
            idx={idx}
            key={idx}
            quoteNumber={quoteNumber}
            firstPage={idx === 0}
            lastPage={idx === singleProducts.length - 1}
            singleProducts={products}
            deck={deck}
            userEmail={userEmail}
          />
        ))}
        {/* Continuities will always be 4 or 5 products max */}
        {continuities?.map((cont, idx) => {
          const products = cont.products?.filter((p) => !!p.uuid) ?? []
          return (
            <DocumentPage
              idx={idx}
              key={`${cont.id}_${idx}`}
              quoteNumber={quoteNumber}
              firstPage={true}
              lastPage={true}
              singleProducts={products}
              deck={deck}
              userEmail={userEmail}
            />
          )
        })}
      </Document>
    )
  },
)

interface IDocumentPageProps {
  idx: number
  firstPage?: boolean
  lastPage?: boolean
  singleProducts: ProductWithQuantity[]
  quoteNumber: string
  deck: IDeckSliceInitialState
  userEmail: string
}
const DocumentPage = memo(
  ({
    idx,
    singleProducts,
    quoteNumber,
    deck,
    firstPage,
    lastPage,
    userEmail,
  }: IDocumentPageProps) => {
    const totalInitialCost = singleProducts.reduce((acc, product) => {
      return acc + product.cost * (Number(product.quantity) ?? 1)
    }, 0)

    return (
      <Page size="A4" style={styles.page}>
        <Form style={{ height: '100%' }}>
          <View style={styles.row}>
            <View style={styles.section}>
              <Image src={GPSLogo} style={styles.logo} />
            </View>
            <View style={styles.section}>
              <Text style={{ marginLeft: 'auto', color: '#50347F' }}>ORDER ACKNOWLEDGEMENT</Text>
            </View>
          </View>
          {/* *********************************************
					Header content
				*********************************************** */}
          {firstPage ? (
            <View style={styles.row}>
              <View style={styles.section}>
                <Text style={styles.text}>Global Promotional Sourcing</Text>
                <Text style={styles.text}>12600 Chaparral Rd, Suite 100</Text>
                <Text style={styles.text}>Henderson, NV</Text>
                <Text style={styles.text}>89044</Text>

                <Text style={[styles.titleText, { marginTop: '20px' }]}>CUSTOMER REFERENCE</Text>
                <View style={styles.inputRow}>
                  <FormField style={styles.formFieldRow} name="customer_ref">
                    <TextInput style={{ flexGrow: 1, height: 20 }} align="left" />
                  </FormField>
                </View>

                <Text style={[styles.titleText, { marginTop: '2px' }]}>Proposal For</Text>
                <View style={styles.inputRow}>
                  <FormField style={styles.formFieldRow} name="client_name">
                    <Text style={styles.textGray}>Client Name</Text>
                    <TextInput style={{ flexGrow: 1 }} align="right" value={deck.recipient} />
                  </FormField>
                </View>
                <View style={styles.inputRow}>
                  <FormField style={styles.formFieldRow} name="client_attention_to">
                    <Text style={styles.textGray}>Attention</Text>
                    <TextInput style={{ flexGrow: 1 }} align="right" />
                  </FormField>
                </View>
                <View style={styles.inputRow}>
                  <FormField style={styles.formFieldRow} name="client_street_address">
                    <Text style={styles.textGray}>Street Address</Text>
                    <TextInput style={{ flexGrow: 1 }} align="right" />
                  </FormField>
                </View>
                <View style={styles.inputRow}>
                  <FormField style={styles.formFieldRow} name="client_city_state_country">
                    <Text style={styles.textGray}>City, State</Text>
                    <TextInput style={{ flexGrow: 1 }} align="right" />
                  </FormField>
                </View>
                <View style={styles.inputRow}>
                  <FormField style={styles.formFieldRow} name="client_zip">
                    <Text style={styles.textGray}>ZIP Code</Text>
                    <TextInput style={{ flexGrow: 1 }} align="right" />
                  </FormField>
                </View>
                <View style={styles.inputRow}>
                  <FormField style={styles.formFieldRow} name="client_phone">
                    <Text style={styles.textGray}>Phone</Text>
                    <TextInput style={{ flexGrow: 1 }} align="right" />
                  </FormField>
                </View>
              </View>

              <View style={styles.section}>
                <Text style={styles.text}>(702) 938-2250</Text>
                <Text style={styles.text}>{userEmail}</Text>
                <Text style={styles.text}>gpspromotions.com</Text>
              </View>

              <View style={styles.section}>
                <Text style={styles.text}>Date: {new Date().toLocaleDateString()}</Text>
                <Text style={styles.text}>Quote #: {quoteNumber}</Text>
                <View style={styles.inputRow}>
                  <FormField style={styles.formFieldRow} name="in_customer">
                    <Text style={styles.text}>Customer:</Text>
                    <TextInput style={{ flexGrow: 1 }} align="right" />
                  </FormField>
                </View>
                <View style={styles.inputRow}>
                  <FormField style={styles.formFieldRow} name="in_hands_date">
                    <Text style={styles.text}>In Hands Date:</Text>
                    <TextInput style={{ flexGrow: 1 }} align="right" />
                  </FormField>
                </View>
                <View style={styles.inputRow}>
                  <FormField style={styles.formFieldRow} name="expiration_date">
                    <Text style={styles.text}>Expiration Date:</Text>
                    <TextInput style={{ flexGrow: 1 }} align="right" />
                  </FormField>
                </View>

                <Text style={[styles.titleText, { marginTop: '24px' }]}>
                  SHIP TO (IF DIFFERENT)
                </Text>
                <View style={styles.inputRow}>
                  <FormField style={styles.formFieldRow} name="client_name_shipto">
                    <Text style={styles.textGray}>Client Name</Text>
                    <TextInput style={{ flexGrow: 1 }} align="right" value={deck.recipient} />
                  </FormField>
                </View>
                <View style={styles.inputRow}>
                  <FormField style={styles.formFieldRow} name="client_attention_shipto">
                    <Text style={styles.textGray}>Attn</Text>
                    <TextInput style={{ flexGrow: 1 }} align="right" />
                  </FormField>
                </View>
                <View style={styles.inputRow}>
                  <FormField style={styles.formFieldRow} name="client_city_state_country_shipto">
                    <Text style={styles.textGray}>City, State</Text>
                    <TextInput style={{ flexGrow: 1 }} align="right" />
                  </FormField>
                </View>
                <View style={styles.inputRow}>
                  <FormField style={styles.formFieldRow} name="client_zip_shipto">
                    <Text style={styles.textGray}>ZIP Code</Text>
                    <TextInput style={{ flexGrow: 1 }} align="right" />
                  </FormField>
                </View>
                <View style={styles.inputRow}>
                  <FormField style={styles.formFieldRow} name="client_phone_shipto">
                    <Text style={styles.textGray}>Phone</Text>
                    <TextInput style={{ flexGrow: 1 }} align="right" />
                  </FormField>
                </View>
              </View>
            </View>
          ) : null}
          {/* *********************************************
					Products content
				*********************************************** */}
          <View style={[styles.row, { marginTop: '4px' }]}>
            <View
              style={[
                styles.descriptionSection,
                {
                  backgroundColor: '#50347F',
                },
              ]}
            >
              <Text style={[styles.titleText, { flexGrow: 1 }]}>DESCRIPTION</Text>
              <Text style={[styles.titleText, { width: 70, marginLeft: 'auto' }]}>IMAGE</Text>
              <Text style={styles.titleText}>UNIT PRICE</Text>
            </View>
            <View style={[styles.quantitySection, { backgroundColor: '#50347F' }]}>
              <Text style={[styles.titleText, { marginLeft: 'auto' }]}>QTY</Text>
            </View>
            <View style={[styles.amountSection, { backgroundColor: '#50347F' }]}>
              <Text style={[styles.titleText, { marginLeft: 'auto' }]}>AMOUNT</Text>
            </View>
          </View>
          {singleProducts?.map((product, idx) => {
            const imageUrl = product.transparentImage
              ? product.transparentImage + '?no-cache=' + Math.random().toString()
              : resizeComputedImage(product.computedPrimaryImage, 150, 150)
            return (
              <View key={`${product.id}_${idx}`} style={styles.row}>
                <View style={styles.descriptionSection}>
                  <View style={{ width: 250 }}>
                    <Text style={styles.text}>{product.name}</Text>
                  </View>
                  <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    {imageUrl ? (
                      <Image
                        src={imageUrl}
                        source={{
                          headers: {
                            'Access-Control-Allow-Origin': '*',
                          },
                        }}
                        // crossOrigin="anonymous"
                        style={styles.productImage}
                      />
                    ) : null}
                    <Text style={{ fontSize: 14, width: 60, textAlign: 'right' }}>
                      {formatter.format(product.cost)}
                    </Text>
                  </View>
                </View>
                <View style={styles.quantitySection}>
                  <FormField style={{ flexGrow: 1, height: 20 }} name={`qty_${product.id}_${idx}`}>
                    <TextInput
                      style={{ flexGrow: 1, fontSize: 10 }}
                      align="right"
                      value={product.quantity}
                    />
                  </FormField>
                </View>
                <View style={styles.amountSection}>
                  <FormField
                    style={{ flexGrow: 1, height: 20 }}
                    name={`total_${product.id}_${idx}`}
                  >
                    <TextInput
                      style={{ flexGrow: 1, fontSize: 12 }}
                      align="right"
                      value={formatter.format(product.cost * (Number(product.quantity) ?? 1))}
                    />
                  </FormField>
                </View>
              </View>
            )
          })}
          {/* **********************************************
					Footer Content
				************************************************* */}
          {lastPage ? (
            <>
              <View style={[styles.section, { flex: 1 }]}>
                <View style={styles.row}>
                  <View style={[styles.section, { paddingLeft: 0 }]}>
                    <View style={{ width: '100%' }}>
                      <Text
                        style={{
                          backgroundColor: '#50347F',
                          padding: 4,
                          fontSize: 10,
                          color: '#FFF',
                        }}
                      >
                        SPECIAL NOTES AND INSTRUCTIONS
                      </Text>
                      <View style={{ height: 80 }}>
                        <FormField
                          style={{ height: 80, fontSize: 14 }}
                          name={`special_notes_${idx}`}
                        >
                          <TextInput multiline style={{ height: 80, fontSize: 14 }} />
                        </FormField>
                      </View>
                    </View>
                  </View>
                  <View style={[styles.section, { paddingRight: 0, paddingLeft: 8 }]}>
                    <View style={styles.inputRow}>
                      <FormField style={[styles.formFieldRow, { height: 20 }]} name="item_total">
                        <Text style={{ fontSize: 14 }}>Item Total</Text>
                        <TextInput
                          style={{ flexGrow: 1, fontSize: 12 }}
                          align="right"
                          value={formatter.format(totalInitialCost)}
                        />
                      </FormField>
                    </View>
                    <View style={styles.inputRow}>
                      <FormField style={[styles.formFieldRow, { height: 20 }]} name="item_freight">
                        <Text style={{ fontSize: 14 }}>Freight</Text>
                        <TextInput
                          style={{ flexGrow: 1, fontSize: 12 }}
                          align="right"
                          value="$0.00"
                        />
                      </FormField>
                    </View>
                    <View style={styles.inputRow}>
                      <FormField style={[styles.formFieldRow, { height: 20 }]} name="tax">
                        <Text style={{ fontSize: 14 }}>Tax</Text>
                        <TextInput
                          style={{ flexGrow: 1, fontSize: 12 }}
                          align="right"
                          value="$0.00"
                        />
                      </FormField>
                    </View>
                    <View style={styles.inputRow}>
                      <FormField style={[styles.formFieldRow, { height: 20 }]} name="total">
                        <Text style={{ fontSize: 14 }}>Total</Text>
                        <TextInput
                          style={{ flexGrow: 1, fontSize: 12 }}
                          align="right"
                          value="$0.00"
                        />
                      </FormField>
                    </View>
                  </View>
                </View>
                <View style={[styles.section, { justifyContent: 'flex-end' }]}>
                  {/* <Text
                    style={{
                      fontSize: 16,
                      fontWeight: 'extrabold',
                      textAlign: 'center',
                      paddingVertical: 4,
                    }}
                  >
                    Thank you for your business!
                  </Text> */}
                  <Text style={[styles.text, { height: 16, textAlign: 'center' }]}>
                    Quotes are valid for 30 days from issuance date
                  </Text>
                  <Text style={[styles.text, { textAlign: 'center' }]}>
                    *Freight cost is an estimate and subject to change
                  </Text>
                </View>
              </View>
            </>
          ) : null}
        </Form>
      </Page>
    )
  },
)

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#FFF',
  },
  row: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
    width: '100%',
    paddingHorizontal: 10,
  },
  inputRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
  },
  formFieldRow: {
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  section: {
    paddingVertical: 8,
    flexGrow: 1,
  },
  descriptionSection: {
    padding: 4,
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  imageSection: {
    width: 100,
    height: 24,
    flexGrow: 0,
    paddingVertical: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },
  quantitySection: {
    width: 50,
    height: 24,
    flexShrink: 1,
    paddingVertical: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },
  amountSection: {
    width: 100,
    height: 24,
    flexGrow: 0,
    paddingVertical: 10,
    paddingRight: 4,
    flexDirection: 'row',
    alignItems: 'center',
  },
  logo: {
    width: 74,
  },
  productImage: {
    width: 50,
    height: 50,
  },
  titleText: {
    fontSize: 10,
    fontWeight: 'bold',
    backgroundColor: '#50347F',
    color: '#FFF',
    paddingLeft: 1,
    paddingVertical: 2,
  },
  text: {
    fontSize: 10,
  },
  textGray: {
    fontSize: 10,
    paddingLeft: 1,
    paddingVertical: 2,
  },
})

ExportQuoteButton.displayName = 'ExportQuoteButton'
DocLayout.displayName = 'DocLayout'
DocumentPage.displayName = 'DocumentPage'
