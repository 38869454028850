import { GenericSelectMenuOption } from 'components/continuityBuilder/Controls'
import { IDirectory, IList } from 'types/modelTypes'
import { store } from '../store'
import {
  addPriceAdjustOption as apao,
  clearState as cs,
  resetPriceAdjustOptions as rpa,
  removeProductFromList as rpfl,
  removeProductsFromList as rpsfl,
  setCrumbs as sc,
  setCurrentContinuitySort as sccts,
  setCurrentDirectory as scd,
  setCurrentList as scl,
  setCurrentProductSort as scps,
  setFilters as sf,
  setFilterQuery as sfq,
  setSearchTerm as sst,
} from './globalsSlice'
import { FilterQuery, IAdminFilter, IDirectoryCrumb, ISortOption } from './types'

export const setFilters = (data: IAdminFilter[]) => store.dispatch(sf(data))

export const setFilterQuery = (data: FilterQuery | null) => store.dispatch(sfq(data))

export const setCrumbs = (data: IDirectoryCrumb[]) => store.dispatch(sc(data))

export const setSearchTerm = (data: string) => store.dispatch(sst(data))

export const setCurrentList = (data: IList | null) => store.dispatch(scl(data))

export const setCurrentDirectory = (data: IDirectory) => store.dispatch(scd(data))

export const addPriceAdjustOption = (data: GenericSelectMenuOption<number>) =>
  store.dispatch(apao(data))

export const resetPriceAdjustOptions = () => store.dispatch(rpa())

export const setCurrentProductSort = (pageNum: ISortOption) => store.dispatch(scps(pageNum))

export const setCurrentContinuitySort = (pageNum: ISortOption) => store.dispatch(sccts(pageNum))

export const clearState = () => store.dispatch(cs())

export const removeProductFromList = (listId: number, productId: number) =>
  store.dispatch(rpfl({ listId, productId }))

export const removeProductsFromList = (listId: number, productIds: number[]) =>
  store.dispatch(rpsfl({ listId, productIds }))
