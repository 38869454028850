import { FocusEvent, memo } from 'react'
import CreatableSelect from 'react-select/creatable'
import VirtualList from 'react-tiny-virtual-list'

interface IAdvancedFilterComboBoxProps {
  disabled?: boolean
  options: any[]
  onChange: (value: any) => void
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void
  className: string
  allowCreate?: boolean
  isMulti?: boolean
  values: any
}
export const AdvancedFilterComboBox = memo(
  ({
    disabled,
    options,
    onChange,
    onBlur,
    className,
    // allowCreate = false,
    isMulti = true,
    values,
  }: IAdvancedFilterComboBoxProps) => {
    return (
      <CreatableSelect
        onChange={onChange}
        isMulti={isMulti}
        name="filters"
        options={options}
        className={`basic-multi-select ${className} scrollbar`}
        value={values}
        classNamePrefix="select"
        isDisabled={disabled}
        onBlur={onBlur}
        styles={{
          control: (provided) => ({
            ...provided,
            borderRadius: 'none',
          }),
          menu: (provided) => ({
            ...provided,
            borderRadius: 'none',
          }),
        }}
        menuPosition="fixed"
        captureMenuScroll={false}
        // allowCreate={allowCreate}
        components={{
          MenuList: ({ children, innerProps }) => {
            if (Array.isArray(children)) {
              return (
                <VirtualList
                  width="100%"
                  height={300}
                  itemCount={options?.length ?? 0}
                  className="scrollbar"
                  itemSize={37} // Also supports variable heights (array or function getter)
                  renderItem={({ index, style }) => (
                    <div key={index} style={style} {...innerProps} className="line-clamp-1">
                      {children[index]}
                      {/* // The style property contains the item's absolute position */}
                    </div>
                  )}
                />
              )
            } else {
              return (
                <div className="h-72 w-full px-4 pt-4 text-center text-gray-400">No Options</div>
              )
            }
          },
        }}
      />
    )
  },
)

AdvancedFilterComboBox.displayName = 'AdvancedFilterComboBox'
