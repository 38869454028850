import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { updateCurrentSlide } from 'data/decks'
import { selectCurrentSlide } from 'data/decks/selectors'
import { useAppSelector } from 'data/reduxHooks'

import { BuilderCoreNotes } from 'components/continuityBuilder/BuilderCoreNotes'
import { FiveItemContinuity } from 'components/continuityBuilder/layouts/FiveItemContinuity'
import { FourItemContinuity } from 'components/continuityBuilder/layouts/FourItemContinuity'
import { FourItemContinuityHorizontal } from 'components/continuityBuilder/layouts/FourItemContinuity--Horizontal'
import { SingleItemContinuity } from 'components/continuityBuilder/layouts/SingleItemContinuity'
import AddProductModal from 'components/modals/AddProductModal'

import { useCaptureSlide } from 'hooks/useCaptureSlide'
import { ContinuityType } from 'types/modelTypes'
import { TitleSlide } from './layouts/TitleSlide'

export const BUIDLER_DIMENSIONS = {
  width: 700,
  height: 394,
}

interface IBuilderCoreProps {
  showAddProductsModalOnLoad: boolean
}
export const BuilderCore = ({ showAddProductsModalOnLoad }: IBuilderCoreProps) => {
  const { captureSlide } = useCaptureSlide()
  const history = useHistory()
  const location = useLocation<{ fromList: boolean }>()

  const { currentSlide, currentSlideIndex } = useAppSelector(selectCurrentSlide)
  const currentDeck = useAppSelector((state) => state.decks)

  const [showAddProductModal, setShowAddProductModal] = useState(false)
  const [slot, setSlot] = useState(0)

  useEffect(() => {
    // Creates a thumbnail of the currently visible slide
    setTimeout(() => {
      captureSlide()
    }, 200)
  }, [currentSlideIndex])

  useEffect(() => {
    if (location.state?.fromList) {
      history.replace('/builder') // reset location.state to prevent re-triggering
      setShowAddProductModal(true)
    }
  }, [location])

  useEffect(() => {
    if (showAddProductsModalOnLoad) {
      setShowAddProductModal(true)
    }
  }, [showAddProductsModalOnLoad])

  function addProduct(slotNum: number) {
    setSlot(slotNum)
    setShowAddProductModal(true)
  }

  function setName(value: string) {
    updateCurrentSlide({
      name: value.toUpperCase(),
    })
  }

  return (
    <div
      className="relative mx-auto h-0 w-full"
      style={{
        paddingBottom: '56.25%',
      }}
    >
      <div
        id="capture"
        className="absolute inset-0 h-full w-full shadow-full-dark"
        style={{
          minWidth: BUIDLER_DIMENSIONS.width + 'px',
          minHeight: BUIDLER_DIMENSIONS.height + 'px',
        }}
      >
        {/* Title slide */}
        {currentSlide.type === ContinuityType.title ? (
          <TitleSlide currentDeck={currentDeck} />
        ) : currentSlide.type === ContinuityType.single ? (
          // single item slide
          <SingleItemContinuity slide={currentSlide} onAddProduct={addProduct} />
        ) : currentSlide.type === ContinuityType.quad ? (
          // four item slide
          <FourItemContinuity slide={currentSlide} onAddProduct={addProduct} />
        ) : currentSlide.type === ContinuityType.quad_horizontal ? (
          <FourItemContinuityHorizontal slide={currentSlide} onAddProduct={addProduct} />
        ) : currentSlide.type === ContinuityType.quint ? (
          // five item slide
          <FiveItemContinuity slide={currentSlide} onAddProduct={addProduct} />
        ) : null}
        {/* Notes below builder */}
        {currentSlide && currentSlide.products && currentSlide.type !== 'title' ? (
          <BuilderCoreNotes slide={currentSlide} deckTitle={'Deck'} />
        ) : null}
      </div>
      <AddProductModal
        show={showAddProductModal}
        title={`Add products${currentDeck.title ? ` to ${currentDeck.title}` : ''}`}
        slot={slot}
        defaultIndex={showAddProductsModalOnLoad ? 1 : 0}
        onClose={() => {
          history.replace('/builder')
          setShowAddProductModal(false)
          captureSlide()
        }}
      />
    </div>
  )
}
