import { XCircleIcon } from '@heroicons/react/outline'
import { PlusIcon } from '@heroicons/react/solid'
import { useCallback, useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { setFilters } from 'data/globals'

import { api } from 'services/api'

import { AdvancedFilterComboBox } from 'components/filters/AdvancedFilterComboBox'
import AdvancedFilterSelect from 'components/filters/AdvancedFilterSelect'
import AdvancedFilterSwitchBox from 'components/filters/AdvancedFilterSwitchBox'
import { TextInput } from 'components/inputs/TextInput'
import { Button } from 'components/small/Button'
import { AndOrType, ComparisonOperator, IAdminFilter } from 'data/globals/types'
import { useAppSelector } from 'data/reduxHooks'

const PROPERTIES = [
  { label: 'Item #', value: 'productId', type: 'string' },
  { label: 'Product Name', value: 'name', type: 'string' },
  { label: 'Vendor', value: 'vendor', type: 'string' },
  { label: 'Brand', value: 'brand', type: 'string' },
  { label: 'Category', value: 'category', type: 'string' },
  { label: 'Subcategory', value: 'subCategory', type: 'string' },
  { label: 'Subcategory 2', value: 'subCategory2', type: 'string' },
  { label: 'Subcategory 3', value: 'subCategory3', type: 'string' },
  { label: 'Subcategory 4', value: 'subCategory4', type: 'string' },
  { label: 'Color', value: 'colors', type: 'string' },
  { label: 'Tags', value: 'tags', type: 'string' },
  { label: 'Material', value: 'material', type: 'string' },
  { label: 'Description', value: 'description', type: 'string' },
  { label: 'Cost', value: 'cost', type: 'int' },
  { label: 'Dropship', value: 'dropship', type: 'bool' },
  { label: 'GPS Warehouse', value: 'warehouse', type: 'bool' },
  { label: 'GPS Exclusive', value: 'exclusive', type: 'bool' },
  { label: 'MSRP', value: 'msrp', type: 'int' },
  { label: 'Inventory', value: 'inventory', type: 'int' },
  { label: 'Lead Time', value: 'leadTime', type: 'string' },
  // { label: 'Collection', value: 'collection', type: 'string' },
  { label: 'Date Updated', value: 'updatedAt', type: 'date' },
  { label: 'Date Added', value: 'createdAt', type: 'date' },
]

const OPERATORS = [
  {
    op: 'contains' as ComparisonOperator,
    label: 'Contains',
    value: 'contains',
    type: ['string'],
    filterType: 'and',
  },
  {
    op: 'not_contains' as ComparisonOperator,
    label: `Doesn't contain`,
    value: 'excludes',
    type: ['string'],
    filterType: 'and',
  },
  {
    op: '=' as ComparisonOperator,
    label: 'Equals',
    value: 'or',
    type: ['string', 'bool', 'int'],
    filterType: 'and',
  },
  {
    op: '!=' as ComparisonOperator,
    label: `Doesn't equal`,
    value: 'not',
    type: ['string', 'bool', 'int'],
    filterType: 'and',
  },
  {
    op: 'not_null' as ComparisonOperator,
    label: 'Has any value',
    value: 'any',
    type: ['string', 'bool', 'int'],
    filterType: 'and',
  },
  {
    op: 'is_null' as ComparisonOperator,
    label: 'Has no value',
    value: 'none',
    type: ['string', 'bool', 'int'],
    filterType: 'and',
  },
  {
    op: '>' as ComparisonOperator,
    label: 'Is greater than',
    value: 'gte',
    type: ['int'],
    filterType: 'and',
  },
  {
    op: '<' as ComparisonOperator,
    label: 'Is less than',
    value: 'lte',
    type: ['int'],
    filterType: 'and',
  },
]

const BOOL_OPTIONS = [
  { label: 'True', value: 'true', type: ['bool'] },
  { label: 'False', value: 'false', type: ['bool'] },
]

type ProductAttribute = {
  name: string
  label: string
  value: string
}
type AttributeState = Record<string, ProductAttribute[]>

interface IAdvancedFilterProps {
  onClose: () => void
}
export default function AdvancedFilters({ onClose }: IAdvancedFilterProps) {
  const filters = useAppSelector((state) => state.globals.filters || [])

  const [attributes, setAttributes] = useState<AttributeState>({})

  const getProductAttributes = useCallback(async () => {
    try {
      const { data } = await api.ProductAttributes.getAll(true)
      const attrs: AttributeState = {}
      for (let attr in data) {
        attrs[attr] = data[attr].map((a) => ({ name: a.name, label: a.name, value: a.name }))
      }
      setAttributes(attrs)
    } catch (error) {
      // alert.error(error.message || 'Could not find tag filters')
    }
  }, [])

  useEffect(() => {
    if (filters.length < 1) {
      setFilters([
        {
          key: uuidv4(),
          complete: false,
          prop: null,
          attr: null,
          operator: null,
          andOr: AndOrType.and,
        },
      ])
    }
  }, [filters])

  useEffect(() => {
    getProductAttributes()
  }, [])

  function clearRow(rowId: string) {
    const newRows = [...filters.filter((r) => r.key !== rowId)]
    setFilters(newRows)
    if (newRows.length === 0) {
      onClose()
    }
  }

  function addRow() {
    if (filters.every((r) => r.complete)) {
      setFilters([
        ...filters,
        {
          key: uuidv4(),
          complete: false,
          prop: null,
          attr: null,
          operator: null,
          andOr: AndOrType.and,
        },
      ])
    }
  }

  function handleClose() {
    setFilters([])
    onClose()
  }

  function onChange(row: IAdminFilter) {
    const rowIdx = filters.findIndex((r) => r.key === row.key)
    if (rowIdx > -1) {
      const newRows = [...filters]
      newRows[rowIdx] = row
      setFilters(newRows)
    }
  }

  return (
    <div className="relative">
      <div className="scrollbar relative z-40 max-h-32 overflow-y-auto bg-gray-100 shadow-md">
        {filters.map((r) => {
          return (
            <FilterRow
              key={r.key}
              row={r}
              attributes={attributes}
              onChange={onChange}
              onClear={clearRow}
            />
          )
        })}
        <Button onClick={addRow} className="my-2 ml-2 mr-auto" size="xs">
          <>
            <PlusIcon className="h-6 w-6 text-white" />
            Add New Filter
          </>
        </Button>
      </div>
      <XCircleIcon
        style={{ top: -8, right: -8 }}
        className="absolute z-40 h-5 w-5 cursor-pointer rounded-full bg-white text-gray-600"
        onClick={handleClose}
      />
    </div>
  )
}

interface IFilterRowProps {
  row: IAdminFilter
  onChange: (row: IAdminFilter & { complete: boolean }) => void
  attributes: AttributeState
  onClear: (rowId: string) => void
}
function FilterRow({ row, onChange, attributes, onClear }: IFilterRowProps) {
  const [activeProp, setActiveProp] = useState(row.prop || null)
  const [activeAttr, setActiveAttr] = useState(row.attr || null)
  const [activeOperator, setActiveOperator] = useState(row.operator || null)
  const [andOrValue, setAndOrValue] = useState(row.andOr || null)

  function handlePropChange(value: IAdminFilter['prop']) {
    setActiveProp(value)
    setActiveAttr([])
    setActiveOperator(null)
  }

  function handleAttributeChange(values: IAdminFilter['attr']) {
    setActiveAttr(values)
  }

  function handleBoolAttributeChange(value: ProductAttribute) {
    setActiveAttr([value])
  }

  function handleOperatorChange(value: IAdminFilter['operator']) {
    setActiveOperator(value)
  }

  function handleAndOrChange(value: IAdminFilter['andOr']) {
    setAndOrValue(value)
  }

  function handleRemoveRow() {
    setActiveAttr(null)
    setActiveProp(null)
    setActiveOperator(null)
    setAndOrValue(null)
    onClear(row.key)
  }

  useEffect(() => {
    const activeAttribute = activeOperator ? activeAttr : []
    let updatedRow = {
      ...row,
      prop: activeProp,
      attr: activeAttribute,
      operator: activeOperator,
      andOr: andOrValue,
      complete:
        !!(activeProp && activeAttribute?.length && activeOperator) ||
        // operators that are true false do not require any attribute
        !!(activeProp && (activeOperator?.value === 'none' || activeOperator?.value === 'any')),
    }
    onChange(updatedRow)
  }, [activeProp, activeAttr, activeOperator, andOrValue])

  return (
    <div className="flex items-center justify-between px-2 py-1">
      <AdvancedFilterSwitchBox
        selected={andOrValue}
        containerClass={'mr-2'}
        onChange={handleAndOrChange}
      />
      <AdvancedFilterSelect
        options={PROPERTIES}
        onChange={handlePropChange}
        value={row.prop}
        className="flex-1 pr-2"
        // selected={activeProp}
        name="properties"
      />
      <AdvancedFilterSelect
        options={OPERATORS}
        onChange={handleOperatorChange}
        className="flex-1 pr-2"
        value={row.operator}
        // selected={activeOperator}
        name="operators"
        filterOption={(opt) => {
          // TODO if opt should be excluded based on the activeProp value
          // TODO dont show greater than for strings for example

          if (activeProp?.type && opt.data.type.includes(activeProp.type)) {
            return true
          }
          return false
          // if (opt.data?.type === activeProp.type) {
          //   return false
          // }
          // return true
        }}
      />
      {row.prop?.type === 'int' ? (
        <TextInput
          type="number"
          value={row.attr?.[0]?.value}
          onChange={(val) => {
            handleAttributeChange([{ name: val, value: val, label: val }])
          }}
          containerClass="flex-1"
        />
      ) : row.prop?.type === 'bool' ? (
        <AdvancedFilterComboBox
          options={BOOL_OPTIONS}
          onChange={handleBoolAttributeChange}
          className="flex-1"
          values={row.attr}
          isMulti={false}
          allowCreate={false}
        />
      ) : (
        <AdvancedFilterComboBox
          options={activeProp ? attributes[activeProp?.label] : []}
          onChange={handleAttributeChange}
          onBlur={({ target }) => {
            if (!target.value.trim() || !activeAttr) return
            handleAttributeChange([
              ...activeAttr,
              { label: target.value, value: target.value, name: target.value },
            ])
          }}
          className="flex-1"
          values={row.attr}
          allowCreate={true}
          disabled={activeOperator?.value === 'any' || activeOperator?.value === 'none'}
        />
      )}
      <XCircleIcon className="ml-4 h-6 w-6 text-gray-600" onClick={handleRemoveRow} />
    </div>
  )
}
